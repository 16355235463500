import {gsap} from "gsap";

jQuery(document).ready(function () {
  let title = jQuery('.quick_access_button__title').width();
  title = title + 15;

  let button = jQuery('.quick_access_button');

  button.each(function () {
    if(jQuery(document).width() >= 1024) {
      const tl_1 = gsap.timeline({repeat: 0, repeatDelay: 1});
      tl_1.fromTo(jQuery(this), {
          x: title,
          duration: 1,
        },
        {
          x: 0,
          duration: 1,
        }
      );
      tl_1.reverse();

      jQuery(this).on('mouseenter', () => {
        tl_1.play();
      });
      jQuery(this).on('mouseleave', () => {
        tl_1.reverse();
      });
      jQuery(this).on('focusin', () => {
        tl_1.play();
      });
      jQuery(this).on('focusout', () => {
        tl_1.reverse();
      });
    }

    jQuery(this).click(function () {
      window.location.href = jQuery(this).find('a').attr('href');
    });
  });

});
